import classNames from 'classnames'
import { focusRing } from '~/utils/styles'
import { useEffect, useRef } from 'react'
import { AlertIcon, InfoIcon } from '~/theme/icons'

export interface AlertProps {
  title?: string
  message?: string
  children?: React.ReactNode
  showIcon?: boolean
  className?: string
  autoFocus?: boolean
  level?: 'info' | 'warning'
}

export const Alert = ({
  title,
  message,
  showIcon = true,
  className,
  level = 'warning',
  // warning: autoFocus should NOT be used if the <Alert> is visible when the page loads.
  // https://www.boia.org/blog/accessibility-tips-be-cautious-when-using-autofocus
  // It should only be used to bring alerts into view after a user action, like a form submission.
  autoFocus = false,
  children,
}: AlertProps): React.ReactElement => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current && autoFocus) {
      // scroll smoothly to ref
      ref.current.focus({ preventScroll: true })
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      })
    }
  }, [
    autoFocus,
    // scroll to ref when data changes (this might be the same code/message, but a new submission)
    title,
    message,
  ])

  const IconComponent = level === 'info' ? InfoIcon : AlertIcon

  return (
    <section
      className={classNames(
        'shadow-1-l scroll-m-10 rounded-lg border-2 px-4 py-3 ring-4',
        level === 'info' && 'border-blue-500 bg-blue-50 ring-blue-50',
        level == 'warning' && 'border-yellow-500 bg-yellow-50 ring-yellow-50',
        focusRing,
        className,
      )}
      ref={ref}
      tabIndex={-1}
    >
      <div className='flex gap-3'>
        {showIcon && (
          <IconComponent
            className={classNames(
              'w-6 flex-shrink-0',
              level === 'info' && 'text-blue-500',
              level === 'warning' && 'text-yellow-500',
            )}
            aria-hidden
          />
        )}
        <div className='flex flex-col justify-center gap-2'>
          {title && (
            <h3
              className={classNames(
                'text-body1 font-medium',
                level === 'info' && 'text-blue-600',
                level === 'warning' && 'text-yellow-600',
              )}
            >
              {title}
            </h3>
          )}
          {message && (
            <div
              className={classNames(
                'text-body2',
                level === 'info' && 'text-blue-900',
                level === 'warning' && 'text-yellow-900',
              )}
            >
              {message}
            </div>
          )}

          {children}
        </div>
      </div>
    </section>
  )
}
